import { createContext, useContext, useEffect, useState } from "react";

const initialAuthState = {
  id: "",
  email: null,
  senha: null,
};

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(initialAuthState);
  const [statusLogin, setStatusLogin] = useState(0);

  useEffect(() => {
    (async () => {
      //   setLoading(false);
    })();
  }, []);

  //   const login = useCallback(async (credentials) => {
  //     setLoading(true);
  //     try {
  //       const response = await AuthService.login(credentials);
  //       setUser(response);
  //       setLoading(false);
  //     } catch (err) {
  //       setError(err.message);
  //       setLoading(false);
  //     }
  //   }, []);

  //   const logout = useCallback(async () => {
  //     setLoading(true);
  //     try {
  //       await AuthService.logout();
  //       setUser(initialAuthState);
  //       setLoading(false);
  //     } catch (err) {
  //       setLoading(false);
  //     }
  //   }, []);

  const setUserActive = (status) => {
    setUser(status);
  };

  const setStatusLoginActive = (status) => {
    setStatusLogin(status);
  };

  return (
    <AuthContext.Provider
      value={{
        statusLogin,
        setStatusLoginActive,
        setUserActive,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  const { setStatusLoginActive, statusLogin, setUserActive, user } = context;
  return {
    statusLogin,
    setStatusLoginActive,
    setUserActive,
    user,
  };
}

export { AuthProvider, useAuth };
