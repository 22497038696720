import styled from "styled-components";

const Container = styled.div`
  /* margin: 5vh 5vw; */
`;

const TextTermo = styled.div`
  margin: 5vh 5vw;
`;

const ContainerTextTermo = styled.div`
  margin: 5vh 5vw;
`;

const Header = styled.div`
  height: 200px;
  background: #688492;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  color: #fff;
  font-size: 30px;
  font-weight: bold;
`;

const SubTitle = styled.p`
  color: #fff;
  font-size: 30px;
  font-weight: bold;
`;

const TitleLGPD = styled.p`
  margin-bottom: 10px;
  margin-top: 50px;
  color: #212326;
  font-size: 20px;
`;

const SubTitleLGPD = styled.p`
  margin-left: 15px;
  color: #6b7177;
  margin-bottom: 20px;
  line-height: 25px;
`;

export {
  Header,
  Title,
  Container,
  TextTermo,
  ContainerTextTermo,
  TitleLGPD,
  SubTitle,
  SubTitleLGPD,
};
