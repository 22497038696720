import { ThemeProvider } from "styled-components";
import "./App.css";
import { AuthProvider } from "./app/context/auth.context";
import AuthScreen from "./app/pages/Auth/AuthScreen";
import Routes from "./Router";
import GlobalStyles from "./styles/GlobalStyles";
import Theme from "./styles/Theme";
function App() {
  return (
    <ThemeProvider theme={Theme}>
      <AuthProvider>
        <GlobalStyles />
        <Routes />
        {/* <AuthScreen></AuthScreen> */}
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
