import React from "react";
import Typographies from "./TypographyStyled";

const TypographyDefaultProps = {
  variant: "body1",
};

const Typography = ({
  children,
  variant,
  color,
  weight,
  marginBottom,
  marginTop,
  ...otherStyles
}) => {
  const Element = Typographies[variant];

  return (
    <Element
      color={color}
      weight={weight}
      marginBottom={marginBottom}
      marginTop={marginTop}
      {...otherStyles}
    >
      {children}
    </Element>
  );
};

Typography.defaultProps = TypographyDefaultProps;

export default Typography;
