import React from "react";

import * as S from "./LogoStyled";

const Logo = ({ size = 160, marginBottom }) => {
  return (
    <S.Img
      style={{ width: size, marginBottom: marginBottom }}
      src={process.env.REACT_APP_LOGO_PRIMARY}
    />
  );
};

export default Logo;
