import styled from "styled-components";

const h1 = styled.h1`
  font-size: ${(props) => props.theme.typography.h1?.fontSize};
  margin-bottom: ${(props) => props.marginBottom};
  margin-top: ${(props) => props.marginTop};
  color: ${(props) => props.color || props.theme.palette.text.primary};
  font-weight: ${(props) =>
    props.weight || `${props.theme.typography.h1?.fontWeight}`};
  /* font-family: ${(props) => `${props.theme.typography.h1?.fontFamily}`}; */
`;

const h2 = styled.h2`
  font-size: ${(props) => props.theme.typography.h2?.fontSize};
  color: ${(props) => props.color || props.theme.palette.text.primary};
  font-weight: ${(props) =>
    props.weight || `${props.theme.typography.h2?.fontWeight}`};
  /* font-family: ${(props) => `${props.theme.typography.h2?.fontFamily}`}; */
`;
const h3 = styled.h3`
  font-size: ${(props) => props.theme.typography.h3?.fontSize};
  color: ${(props) => props.color || props.theme.palette.text.primary};
  font-weight: ${(props) =>
    props.weight || `${props.theme.typography.h3?.fontWeight}`};
  /* font-family: ${(props) => `${props.theme.typography.h2?.fontFamily}`}; */
`;

const a = styled.a`
  font-size: ${(props) => props.theme.typography.a?.fontSize};
  color: ${(props) => props.color || props.theme.palette.text.primary};
  font-weight: ${(props) =>
    props.weight || `${props.theme.typography.a?.fontWeight}`};
  /* font-family: ${(props) => `${props.theme.typography.h2?.fontFamily}`}; */
`;

const body1 = styled.p`
  font-size: ${(props) => props.theme.typography.body1?.fontSize};
  color: ${(props) => props.color || props.theme.palette.text.primary};
  font-weight: ${(props) =>
    props.weight || `${props.theme.typography.body1?.fontWeight}`};
  /* font-family: ${(props) =>
    `${props.theme.typography.body1?.fontFamily}`}; */
`;
const body2 = styled.p`
  font-size: ${(props) => props.theme.typography.body2?.fontSize};
  color: ${(props) => props.color || props.theme.palette.text.primary};
  font-weight: ${(props) =>
    props.weight || `${props.theme.typography.body2?.fontWeight}`};
  /* font-family: ${(props) =>
    `${props.theme.typography.body1?.fontFamily}`}; */
`;

const Typographies = {
  h1,
  h2,
  h3,
  body1,
  body2,
  a
};

export default Typographies;
