import React, { useEffect, useRef, useState } from "react";
import { Card, Typography } from "../../../../components";
import { Input, Button } from "../../../../components";
import * as S from "./ForceResetPasswordStyled";
import { api, apiUser } from "../../../../services/base.service";
import * as Yup from "yup";
import { useAuth } from "../../../../context/auth.context";
import { setItem } from "../../../../services/storage.service";
import { useCookies } from "react-cookie";

const TYPE_USER = { RESPOSIBLE: 5, STUDENT: 6, TEACHER: 4, ADM_MASTER: 5 };

const ForgotPasswordScreen = (props) => {
  const formRef = useRef(null);
  const [errorAuth, setErrorAuth] = useState([]);
  const [loader, setLoader] = useState(false);
  const { statusLogin, setStatusLoginActive } = useAuth();
  const main_domain_localhost = "localhost";
  const main_domain = process.env.REACT_APP_MAIN_DOMAIN;
  const main_url = process.env.REACT_APP_MAIN_URL;
  const tutor_url = process.env.REACT_APP_TUTOR_URL;
  const hub_url = process.env.REACT_APP_HUB_URL;
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);

  useEffect(() => {}, []);

  const verificarDataDeNascimento = (data) => {
    setLoader(true);
    console.log({
      id: props.id,
      dataDeNascimento: data.dataDeNascimento,
    });
    // console.log(cookies);
    apiUser
      .post("usuario/identifica-data", {
        id: props.id,
        dataNascimento: data.dataDeNascimento,
      })
      .then(
        (res) => {
          setLoader(false);
          setStatusLoginActive(2);
          console.log(res);
        },
        (error) => {
          setLoader(false);
          if (error.response.data.errors) {
            setErrorAuth(error.response.data.errors);
          } else {
            setErrorAuth(["Ocorreu um erro insperado"]);
          }
        }
      );
  };
  async function handleSubmit(data) {
    setErrorAuth([]);
    try {
      console.log(data);
      verificarDataDeNascimento(data);
      // formRef.current.setErrors({});
      const schema = Yup.object().shape({
        dataNascimento: Yup.string().required("Esse campo é obrigatório"),
      });
      // await schema.validate(data, {
      //   abortEarly: false,
      // });
      // console.log(data);
      // postAuth(data);
    } catch (error) {
      console.log(error);

      //   console.log(err);
      //   const validationErrors = {};
      //   if (err instanceof Yup.ValidationError) {
      //     err.inner.forEach((error) => {
      //       validationErrors[error.path] = error.message;
      //     });
      //     formRef.current.setErrors(validationErrors);
      //   }
      // }
    }
  }

  const AleterarSenha = (data) => {
    setLoader(true);
    console.log({
      id: props.id,
      senha: data.senha,
      senhaConfirmacao: data.senhaConfirmacao,
    });
    // console.log(cookies);
    api
      .put("identidade/atualizar-senha", {
        id: props.id,
        senha: data.senha,
        senhaConfirmacao: data.senhaConfirmacao,
      })
      .then(
        (res) => {
          console.log(res);
          postAuth(data);
        },
        (error) => {
          console.log(error.response.data.errors);
          setErrorAuth(error.response.data.errors);
          setLoader(false);
        }
      );
  };

  const postAuth = (data) => {
    api
      .post("identidade/autenticar", {
        login: props.login,
        senha: data.senha,
      })
      .then((res) => {
        const config = {
          headers: { Authorization: `Bearer ${res.data.data.accessToken}` },
        };
        apiUser
          .get(`Usuario/${res.data.data.usuarioToken.id}`, config)
          .then((result) => {
            setCookie("token", res.data.data.accessToken, {
              path: "/",
              domain: main_domain,
            });
            setCookie("name", result.data.nome, {
              path: "/",
              domain: main_domain,
            });
            setCookie("id", res.data.data.usuarioToken.id, {
              path: "/",
              domain: main_domain,
            });

            setCookie("token", res.data.data.accessToken, {
              path: "/",
              domain: main_domain_localhost,
            });
            setCookie("name", result.data.nome, {
              path: "/",
              domain: main_domain_localhost,
            });
            setCookie("id", res.data.data.usuarioToken.id, {
              path: "/",
              domain: main_domain_localhost,
            });
            setItem("token", res.data.data.accessToken);
            setItem("name", result.data.nome);
            setItem("email", res.data.data.usuarioToken.email);
            setItem("tipoUsuario", result.data.tipoUsuario);

            directUser(result.data.tipoUsuario);
            setLoader(false);
          });
      })
      .catch((error) => {
        setErrorAuth(["Usuário ou Senha incorretos"]);
        setLoader(false);
      });
  };
  async function handleAlterarSenha(data) {
    setErrorAuth([]);
    try {
      console.log(data);
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        senha: Yup.string().required("Esse campo é obrigatório"),
        senhaConfirmacao: Yup.string().required("Esse campo é obrigatório"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      // console.log(data);
      AleterarSenha(data);
    } catch (err) {
      console.log(err);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      // }
    }
  }

  const directUser = (typeUser) => {
    switch (typeUser) {
      case 2:
      case 8:
        window.location = hub_url;
        break;
      case 3:
        window.location = hub_url;
        break;
      case TYPE_USER.TEACHER:
        window.location = hub_url;
        break;
      case 5:
        setErrorAuth(["Usuário ou Senha incorretos"]);
        break;
      case 6:
        window.location = tutor_url;
        break;
      case 7:
        window.location = hub_url;
        break;
      default:
        return;
    }
  };
  return (
    <>
      {statusLogin === 1 ? (
        <Card variant="h3" title="Olá, para garantir a segurança na plataforma, confirme sua identificação inserindo sua: ">
          <S.FormContainer ref={formRef} onSubmit={handleSubmit}>
            <Input
              label="Data de nascimento"
              name="dataDeNascimento"
              type="text"
              mask={true}
            />
            {errorAuth.length > 0 && (
              <S.MensageError>- {errorAuth[0]}</S.MensageError>
            )}
            <Button loader={loader}>Confimar</Button>
          </S.FormContainer>
        </Card>
      ) : statusLogin === 2 ? (
        <Card variant="h3" title="Para acessar, altere sua senha inserindo uma nova:">
          <S.FormContainer ref={formRef} onSubmit={handleAlterarSenha}>
            <Input label="Nova senha" name="senha" type="password" />
            <Input
              label="confirmar nova senha"
              name="senhaConfirmacao"
              type="password"
            />
            {errorAuth.length > 0 && (
              <S.MensageError>- {errorAuth[0]}</S.MensageError>
            )}
            <Button loader={loader}>Confimar</Button>
          </S.FormContainer>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};

export default ForgotPasswordScreen;
