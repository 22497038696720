import styled from "styled-components";
import InputMask from "react-input-mask";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  display: flex;
  margin-bottom: 15px;
  width: 100%;
`;

const Label = styled.label`
  position: absolute;
  top: 0;
  left: -5px;
  padding: inherit;
  margin: 0;
  -webkit-transform: translateX(1.3rem) translateY(0.4rem);
  transform: translateX(1.3rem) translateY(0.4rem);
  z-index: 50;
  color: #637381;
  font-size: 13px;
  padding: 0px 0;
`;

const Input = styled.input`
  padding: 24px 14px 10px;
  font-size: 15px;
  border: 0.1rem solid #8c9196;
  border-radius: 4px;
`;

const MensageError = styled.span`
  color: red;
  margin-top: 5px;
  font-size: 13px;
`;

const InputMasko = styled(InputMask)`
  padding: 24px 14px 10px;
  font-size: 15px;
  border: 0.1rem solid #8c9196;
  border-radius: 4px;
`;
export { Container, Label, Input, MensageError, InputMasko };
