const setItem = (key, string) => {
  localStorage.setItem(key, string);
};

const getItem = (key) => {
  return localStorage.getItem(key);
};

const cleanStorage = () => {
  localStorage.clear();
  // localStorage.removeItem("token");
};

export { setItem, getItem, cleanStorage };
